import "./services.scss";

import one from "../../img/services/1.png";
import two from "../../img/services/2.png";
import three from "../../img/services/3.png";
import four from "../../img/services/4.png";
import five from "../../img/services/5.png";
import six from "../../img/services/6.png";

const Services = () => {
  return (
    <section id="service" className="services">
      <div className="container">
        <div className="services__content">
          <div className="services__content-title">
            <h2>Услуги</h2>
          </div>
          <div className="services__content-cards">
            <div
              style={{
                backgroundImage: `url(${one})`,
              }}
              className="services__content-cards-item"
            >
              <h5>Общестроительные работы</h5>
              <div className="services__content-cards-item-info">
                <h5>Общестроительные работы</h5>
                <p>
                  Первым делом специалисты проводят проектно-изыскательные
                  работы. На этом этапе составляют проектную документацию, а
                  также сметы, которые позволяют определить стоимость
                  строительства объекта.
                </p>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(${two})`,
              }}
              className="services__content-cards-item"
            >
              <h5>Аренда строительной техники</h5>
              <div className="services__content-cards-item-info">
                <h5>Аренда строительной техники</h5>
                <p>
                  Самостоятельно организуем транспортировку спецтехники в
                  согласованные сроки.
                </p>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(${three})`,
              }}
              className="services__content-cards-item"
            >
              <h5>Реконструкция зданий и сооружений</h5>
              <div className="services__content-cards-item-info">
                <h5>Реконструкция зданий и сооружений</h5>
                <p>
                  Используем современное оборудование, материалы и технологии,
                  позволяющие оптимизировать временные, трудовые и финансовые
                  затраты.
                </p>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(${four})`,
              }}
              className="services__content-cards-item"
            >
              <h5>Внутренние монтажные работы</h5>
              <div className="services__content-cards-item-info">
                <h5>Внутренние монтажные работы</h5>
                <p>
                  Производим внутренние строительно-монтажные работы широкого
                  спектра.
                </p>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(${five})`,
              }}
              className="services__content-cards-item"
            >
              <h5>Электромонтажные работы</h5>
              <div className="services__content-cards-item-info">
                <h5>Электромонтажные работы</h5>
                <p>
                  Все работы производим согласно действующим нормативным
                  документам и стандартам.
                </p>
              </div>
            </div>

            <div
              style={{
                backgroundImage: `url(${six})`,
              }}
              className="services__content-cards-item"
            >
              <h5>Наружные монтажные работы</h5>
              <div className="services__content-cards-item-info">
                <h5>Наружные монтажные работы</h5>
                <p>
                  Проводим полный цикл работ по монтажу сетей отопления,
                  водоснабжения, канализации, водоотведения, а также установке
                  гидротехнических комплексов, очистных сооружений, насосных
                  станций, коллекторов.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
