import "./advantages.scss";
import adv from "../../img/advantages.png";
const Advantages = () => {
  return (
    <section id="advantages" className="advantages">
      <img className="bg" src={adv} alt="Фоновое изображение" />
      <div className="container">
        <div className="advantages__content">
          <div className="advantages__content-left">
            <div className="square">
              <h3>Преимущества</h3>
            </div>
          </div>
          <div className="advantages__content-right">
            <h3>Качество, которое остается на высоте</h3>
            <p>
              Мы дорожим своей репутацией, поэтому обеспечиваем непрерывный
              контроль качества выполнения всех этапов работ.
            </p>
            <div className="advantages__content-right-stat">
              <div className="advantages__content-right-stat-item">
                <h3>14 лет</h3>
                <span>на рынке</span>
              </div>
              <div className="advantages__content-right-stat-item">
                <h3>+ 579</h3>
                <span>завершенных проектов</span>
              </div>
              <div className="advantages__content-right-stat-item">
                <h3>100%</h3>
                <span>гарантия</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
