import logo from "../../img/logo_black.svg";
import donstroy from "../../img/donstroy.svg";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__content-logo">
            <img src={logo} alt="Логотип" />
            <p>© 2022 penthouse.ru</p>
          </div>
          <div className="footer__content-nav mt-3">
            <h5>Навигация</h5>
            <nav>
              <ul>
                <li>
                  <a href="#main">Главная</a>
                </li>
                <li>
                  <a href="#service">Услуги</a>
                </li>
                <li>
                  <a href="#advantages">Преимущества</a>
                </li>
                <li>
                  <a href="#works">Наши работы</a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="footer__content-contacts mt-3">
            <h5>Контакты</h5>
            <ul>
              <li>
                <p>8-800-600-53-82 </p>
              </li>
              <li>
                <p>8-800-600-53-82</p>
              </li>
              <li>
                <p>info@penthaus.city</p>
              </li>
            </ul>
          </div>
          <div className="footer__content-adress mt-3">
            <h5>Юридический адрес</h5>
            <p>ООО "Пентхаус"</p>
            <p>
              123112, г. Москва, набережная Пресненская, дом 8, строение 1,
              помещение 603М, комната 7
            </p>
          </div>
          <div className="footer__content-partners mt-3">
            <h5>Наш партнер</h5>
            <img src={donstroy} alt="Донстрой" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
