import {
  Header,
  Main,
  Services,
  Advantages,
  Works,
  Contacts,
  Feedback,
  Footer,
} from "./sections";
import { ToTopButton } from "./components";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <div className="main__bg">
        <Header />
        <Main />
      </div>
      <Services />
      <Advantages />
      <Works />
      <Contacts />
      <Feedback />
      <Footer />
      <ToTopButton />
    </div>
  );
}

export default App;
