import "./main.scss";

const Main = () => {
  return (
    <main id="main" className="main">
      <div className="container">
        <div className="main__content">
          <div className="main__content-title">
            <div className="square">
              <div className="main__content-title-text">
                <span>Пентхаус</span>
                <h1>Строительно-монтажные работы</h1>
              </div>
            </div>
            <p>
              Гарантируем качественное выполнение работ, обеспечивая гибкий
              подход к требованиям Заказчика и оперативность в выполнении
              поставленных задач.
            </p>
            <a href="#feedback">
              <button className="btns btns-primary">Заказать звонок</button>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Main;
